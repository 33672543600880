<script>
import AdvancedSearch from '@/components/search/advanced/AdvancedSearch.vue'
import LibraryMetadataMixin from '@/components/search/advanced/LibraryMetadataMixin.vue'

export default {
  name: 'MusicAudioVideoAdvancedSearch',
  extends: AdvancedSearch,
  mixins: [LibraryMetadataMixin],
  computed: {
    limitFieldConfig() {
      return [
        this.standardField('australian_content'),
        this.standardField('australian_electronic_publications'),
        this.standardField('isbn'),
        this.standardField('ismn'),
        this.standardField('public_tag'),
        this.standardField('format'),
        this.standardField('place'),
        this.standardField('access'),
        this.standardField('year'),
        this.standardField('language'),
        this.standardField('austlang'),
        this.standardField('library'),
      ]
    },
  },
}
</script>
